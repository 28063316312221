<template>
  <div>
    <h1 class="titulo">Meus Dados</h1>
  
		<vs-form-usuario 
			:obj="$root.sessao" 
			:isEdicao="true" 
			:exibeFoto="true" 
			:exibeEndereco="true"
      :exibeSenha="true" 
			:exibeMensagem="$root.sessao.perfil === VS_PERFIL_FILIAL"
      :exibePerfilVipp="validaPerfil()"
	  :emailObrigatorio="true"
			:isAutoCadastro="true" />
	</div>
</template>

<script>
import VsFormUsuario from "../templates/vs-form-usuario.vue"

export default {
	// #region configs
  name: "meus-dados",
  path: "/meus-dados",
  requireAuth: true,
  components: { VsFormUsuario, },
	// #endregion

	// #region triggers
  created() {
    this.$vsDefineAcesso([
			this.VS_PERFIL_CLIENTE,
			this.VS_PERFIL_CONTRATANTE,
		])

    this.$root.sessao.edicao = true
    this.$root.sessao.senha = ""
  },
	// #endregion

	// #region metodos
  methods: {
  	validaPerfil() {
      return ([this.VS_PERFIL_CONTRATANTE, this.VS_PERFIL_FILIAL].includes(this.$root.sessao.perfil))
    },
  },
	// #endregion
}
</script>