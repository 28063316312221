<template>
  <div>
		<div class="titulo">
			<h1>Equipe</h1>
			<h4>Cadastro e edição de colaboradores</h4>
		</div>

    <div class="h5">
      <!-- #region novo cadastro -->
      <a v-b-toggle.novoCadastro @click.prevent>
        <span class="cor-azul"><b-icon icon="plus" scale="1" variant="primary" />&nbsp;Novo usuário</span>
      </a>
      <b-collapse id="novoCadastro">
        <vs-form-usuario 
					:obj="form" 
					:arrayPerfis="perfis" 
					:exibePerfil="telaFilial" 
					:exibeFoto="true" 
					:isPF="true"
                    :arrayFiliais="filiais" 
					:exibeSenha="true" 
					:temPesquisaCpfCnpj="true" 
                         :emailObrigatorio="true"
					v-on:evento="listaCadastros"
					v-on:eventoPesquisaCpfCnpj="pesquisaCpfCnpj"  />
      </b-collapse>
      <!-- #endregion -->

      <!-- #region filtro -->
			<form @submit.prevent="listaCadastros()">
				<b-form-group label="Filtrar por:" label-size="lg" class="mt-5">
					<b-form-row>
						<b-col md="4">
							<b-form-group class="form-input-cinza" label="Nome" label-cols="12" label-size="lg">
								<b-form-input type="text" v-model="filtro.equipe.nome" autocomplete="on" />
							</b-form-group>
						</b-col>
						<b-col md="2">
							<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
								<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
									<b-icon icon="search" scale="1" />
								</b-button>
							</b-form-group>
						</b-col>
					</b-form-row>
				</b-form-group>
			</form>
      <!-- #endregion -->

      <!-- #region listagem/edicao -->
      <div class="accordion" role="tablist">
        <b-table class="text-nowrap h5" :id="`tabelaEquipe`" small responsive :items="cadastros"
          :fields="cabecalhoTabelaEquipe" :per-page="totalPagina" :current-page="paginaAtual" borderless>

          <template #cell(equipe)="eqp">
            <div class="abre-fecha bg-white py-3 px-2 rounded" :id="`cadastro${eqp.item.id}`">
              <!-- #region cabecalho -->
              <a v-b-toggle :href="`#editaCadastro${eqp.item.id}`" @click.prevent="configuraEdicao(eqp.item)">
                <span class="h3">
                  <b-img rounded="circle" alt="Foto" v-bind:src="$vsDefineFoto(eqp.item.fotoBase64)" width="70" height="70" />
                  &nbsp;&nbsp;&nbsp;{{ eqp.item.nome }}
                </span> .
                <span>{{ eqp.item.cargo }}</span>
              </a>

              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b-button v-if="eqp.item.isAtivo" variant="outline-info"
                  @click.prevent="mudarStatus(eqp.item)">ativado</b-button>
                <b-button v-else variant="outline-danger"
                  @click.prevent="mudarStatus(eqp.item)">inativado</b-button>
              </span>

              <a class="float-right mt-3 mr-2" v-b-toggle :href="`#editaCadastro${eqp.item.id}`"
                @click.prevent="configuraEdicao(eqp.item)">
                <b-icon class="when-opened" icon="chevron-up" scale="1.2" />
                <b-icon class="when-closed" icon="chevron-down" scale="1.2" />
              </a>
              <!-- #endregion -->

              <!-- #region corpo -->
              <b-collapse v-if="idSanfonaAberta === eqp.item.id" :id="`editaCadastro${eqp.item.id}`"
                accordion="sanfonaFluxo" role="tabpanel">
                <vs-form-usuario 
									:obj="eqp.item" 
									:arrayPerfis="perfis" 
									:isEdicao="true" 
									:isPF="true"
                    :exibeFoto="true" 
									:exibePerfil="telaFilial" 
									:exibeSenha="true" 
									:arrayFiliais="filiais"
                  :exibeExclusao="true" 
                  :emailObrigatorio="true"
									v-on:evento="listaCadastros" />
              </b-collapse>
              <!-- #endregion -->
            </div>
          </template>
        </b-table>

        <div v-if="cadastros.length" class="mt-4">
          <span style="padding-left: 5px;padding-right: 20px;">
            <b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
          </span>
          <span>Total de Regitros: {{ $vsFormataNumero(cadastros.length) }}</span>
          <b-pagination class="mt-3 d-flex justify-content-center" :total-rows="cadastros.length"
            :per-page="totalPagina" v-model="paginaAtual" aria-controls="tabelaEquipe" />
        </div>
      </div>
      <!-- #endregion -->
    </div>
  </div>
</template>

<script>
import VsFormUsuario from "../templates/vs-form-usuario.vue"

export default {
  // #region configs
  name: "equipe",
  path: "/equipe",
  requireAuth: true,
  components: { VsFormUsuario },
  // #endregion

  // #region dados
  data() {
    return {
      telaFilial: false,
      idSanfonaAberta: null,
      form: {
        nome: "",
        fotoUpload: null,
        telefone: "",
        cpfCnpj: "",
        email: "",
        senha: "",
        senha2: "",
        isAtivo: true,
        isExcluido: false,
        verSenha: false,
				perfil: null,
        endereco: null,
        vinculos: [],
        mensagemCustomizada: "",
      },
      perfis: [],
      cadastros: [],
      filiais: [],
      filtro: {
        equipe: {
          nome: "",
        }
      },
      cabecalhoTabelaEquipe: [
        { key: "equipe", sortable: false, label: "Equipe", thClass: "d-none", tdClass: "py-2" },
      ],
      paginaAtual: 1,
      totalPagina: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_FILIAL, this.VS_PERFIL_GERENTE])

    let sessao = this.$root.sessao
    let telaFilial = (sessao.perfil === this.VS_PERFIL_FILIAL)

    this.form.perfil = (telaFilial) ? this.VS_PERFIL_GERENTE : this.VS_PERFIL_OPERADOR
    this.perfis = [
      { value: this.VS_PERFIL_GERENTE, text: "Gerente" },
      { value: this.VS_PERFIL_OPERADOR, text: "Operador" },
    ]
    this.filiais = [telaFilial ? sessao : sessao.filial]
    this.telaFilial = telaFilial
  },
  mounted() {
    //this.listaCadastros()
  },
  // #endregion

  // #region metodos
  methods: {
		async pesquisaCpfCnpj() {
			let form = this.form
			let cpfCnpj = this.$vsLimpaFormatacao(form.cpfCnpj)

			if (![11, 14].includes(cpfCnpj.length)) {
				return
			}

			await this.listaCadastros(cpfCnpj)

			if (this.cadastros.length) {
				this.$vsNotificacao(`CPF/CNPJ: ${this.$vsFormataCpfCnpj(cpfCnpj)} já está cadastrado, verifique a lista abaixo`, "info")
			}
		},
    async mudarStatus(obj) {
      let status = !obj.isAtivo
      let resp = await this.$vsApiRest("PUT", `/usuarios/status/${obj.id}/${status}`)
        
			if (resp.status === 200) {
        obj.isAtivo = status
        this.$vsNotificacao(resp.data.msg, "success")
      }
    },
    async listaCadastros(cpfCnpj="") {
      // busca cadastros para listagem
      let sessao = this.$root.sessao
      let idContratante = (sessao.perfil === this.VS_PERFIL_CONTRATANTE)
        ? sessao.id
        : sessao.contratante.id
			let nome = this.filtro.equipe.nome
      let query = `idContratante=${idContratante}`

			if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `&idFilial=${sessao.id}`
			}
			if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query += `&idFilial=${sessao.filial.id}&perfil=${this.VS_PERFIL_OPERADOR}`
			}
      if (nome) {
        query += `&nome=${nome}`
      }
			if (cpfCnpj.length) {
				query += `&cpfCnpj=${cpfCnpj}`
			}

			this.cadastros = []

      let resp = await this.$vsApiRest("GET", `/usuarios/equipe/?${query}`)
        
			if (resp.status === 200) {
        this.cadastros = resp.data.filter(el => !el.isExcluido)
      }
    },
    configuraEdicao(obj) {
      obj.edicao = true
      obj.senha = ""
      obj.fotoUpload = null
      obj.cpfCnpj = this.$vsFormataCpfCnpj(obj.cpfCnpj)
      obj.telefone = this.$vsFormataTelefone(obj.telefone)
      obj.mensagemCustomizada = obj.mensagemCustomizada || ""

      if (!obj.endereco) {
        obj.endereco = {
          tipo: this.VS_TIPO_ENDERECO_RUA,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          apelido: "",
          complemento: "",
          isComercial: false,
        }
      }
      obj.endereco.cep = this.$vsFormataCep(obj.endereco.cep)
      obj.vinculos = obj.vinculos || []

      this.lazyLoadSanfona(obj.id)
    },
    lazyLoadSanfona(id) {
      this.$vsLoadingStart(true, 300)
      this.idSanfonaAberta = id
    },
  }
  // #endregion
}
</script>
