<template>
  <div>
		<div class="titulo">
			<h1>Clientes</h1>
			<h4>Cadastro de clientes e dados contratuais</h4>
		</div>

    <div class="h5">
      <!-- #region novo cadastro -->
      <a v-b-toggle.novoCliente @click.prevent>
        <span class="cor-azul"><b-icon icon="plus" scale="1" variant="primary" />&nbsp;Novo cadastro</span>
      </a>
      <b-collapse id="novoCliente" class="mt-3">
        <vs-form-usuario 
					:obj="formCliente" 
					:arrayPerfis="formCliente.perfis" 
					:exibeEndereco="true"
                    :exibeFoto="true"
					:exibeSenha="liberaSenhaCadastro"
					:temPesquisaCpfCnpj="true"
                    :emailObrigatorio="true"
					v-on:evento="listaCadastros"
					v-on:eventoPesquisaCpfCnpj="pesquisaCpfCnpj" />
      </b-collapse>
      <!-- #endregion -->

      <!-- #region filtro -->
      <div class="mt-4 mb-3">
				<form @submit.prevent="listaCadastros()">
					<b-form-group label="Filtrar por:">
						<b-form-row>
							<b-col cols="3">
								<b-form-group class="form-input-cinza" label="Nome" label-cols="12">
									<b-form-input type="text" size="sm" v-model="filtro.nome" autocomplete="on" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="CPF/CNPJ" label-cols="12">
									<b-form-input type="text" size="sm" v-model="filtro.cpfCnpj" autocomplete="on" @keyup="mascaraCpfCnpj()" />
								</b-form-group>
							</b-col>
							<b-col md="3">
								<b-form-group class="form-input-cinza" label="Email" label-cols="12">
									<b-form-input type="text" size="sm" v-model="filtro.email" autocomplete="on" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Telefone" label-cols="12">
									<b-form-input type="text" size="sm" v-model="filtro.telefone" autocomplete="on" @keyup="mascaraTelefone()" />
								</b-form-group>
							</b-col>
							<b-col md="1">
								<b-form-group class="form-input-cinza" label="CEP" label-cols="12">
									<b-form-input type="text" size="sm" v-model="filtro.cep" autocomplete="on" @keyup="mascaraCep()" />
								</b-form-group>
							</b-col>
							<b-col md="1">
								<b-form-group style="color: transparent;" label="." size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
      </div>
      <!-- #endregion -->

      <!-- #region listagem/edicao -->
      <div class="accordion" role="tablist">
        <b-table class="text-nowrap h5" :id="`tabelaCliente`" small responsive :items="formCliente.cadastros"
          :fields="cabecalhoTabelaCliente" :per-page="totalPagina" :current-page="paginaAtual" borderless>

          <template #cell(cliente)="cli">
            <div class="bg-white py-3 px-2 rounded" :id="`cadastro${cli.item.id}`">
              <!-- #region cabecalho -->
              <div>
								<!-- #region descritivo -->
								<div class="abre-fecha mb-3">
									<a v-b-toggle :href="`#editaCadastro${cli.item.id}`" @click.prevent="lazyLoadSanfona(cli.item.id)" v-b-tooltip.hover title="Mostrar/Ocultar cadastro">
										<span class="h3">
											<b-img rounded="circle" alt="Foto" v-bind:src="$vsDefineFoto(cli.item.fotoBase64)" width="70" height="70" />
											&nbsp;&nbsp;&nbsp;{{ cli.item.nome }}
										</span>
										<span>&nbsp;.&nbsp;{{ cli.item.cargo }}</span>
									</a>
									<a class="float-right mt-3 mr-2" v-b-toggle :href="`#editaCadastro${cli.item.id}`" @click.prevent="lazyLoadSanfona(cli.item.id)" v-b-tooltip.hover title="Mostrar/Ocultar cadastro">
										<b-icon class="when-opened" icon="chevron-up" scale="1.2" />
										<b-icon class="when-closed" icon="chevron-down" scale="1.2" />
									</a>
								</div>
								<!-- #endregion -->

								<!-- #region opcoes -->
                <span v-b-tooltip.hover title="Mudar status do cadastro">
                  &nbsp;
                  <b-button v-if="cli.item.isAtivo" variant="outline-info" @click.prevent="mudarStatus(cli.item)">ativo</b-button>
                  <b-button v-else variant="outline-danger" @click.prevent="mudarStatus(cli.item)">inativo</b-button>
                </span>

								<span v-if="[VS_PERFIL_FILIAL, VS_PERFIL_GERENTE].includes($root.sessao.perfil)">
                  &nbsp;
                  <b-button v-if="liberaEnvioConvite(cli.item)" variant="outline-primary" @click.prevent="enviaConvite(cli.item)" v-b-tooltip.hover title="Enviar um convite por email">convidar para vínculo</b-button>
									<span v-else-if="conviteAguardando(cli.item)" class="text-secondary" v-b-tooltip.hover title="Status do convite enviado">
										<b-icon icon="clock" /> Convite aguardando resposta (válido até {{ $vsFormataData(conviteDataExpiracao(cli.item), true) }})
									</span>
									<span v-else-if="conviteAceito(cli.item)" class="text-success" v-b-tooltip.hover title="Status do convite enviado">
										<b-icon icon="check-circle" /> Convite aceito
									</span>
									<span v-else-if="conviteRecusado(cli.item)" class="text-warning" v-b-tooltip.hover title="Status do convite enviado">
										<b-icon icon="x-circle" /> Convite recusado
									</span>
                </span>
								<!-- #endregion -->

								<!-- #region status dos contratos -->
								<span class="float-right mr-2" v-b-tooltip.hover title="Status do(s) contrato(s)">
									<span v-if="cli.item.totalContratoAtivo" class="text-info">
										<b-icon icon="check" scale="1.3" /> {{ $vsListaContratosStatus(VS_CONTRATO_STATUS_ATIVO).text }} ({{ cli.item.totalContratoAtivo }})
									</span>
									<span v-if="cli.item.totalContratoInativo" class="text-warning">
										<b-icon icon="dot" scale="2.8" /> {{ $vsListaContratosStatus(VS_CONTRATO_STATUS_INATIVO).text }} ({{ cli.item.totalContratoInativo }})
									</span>
									<span v-if="cli.item.totalContratoSuspenso" class="text-danger">
										<b-icon icon="x" scale="1.3" /> {{ $vsListaContratosStatus(VS_CONTRATO_STATUS_SUSPENSO).text }} ({{ cli.item.totalContratoSuspenso }})
									</span>
								</span>
								<!-- #endregion -->
              </div>
              <!-- #endregion -->

              <!-- #region corpo -->
              <b-collapse v-if="idSanfonaAberta === cli.item.id" :id="`editaCadastro${cli.item.id}`" class="mt-4">
                <b-form-row>
                  <!-- #region box cadastro -->
                  <b-col md="8" class="border-right">
                    <span class="h3">Informações pessoais</span>
                    <span v-if="liberaEdicaoCliente(cli.item)">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b-button v-if="!isEdicao" variant="outline-warning" @click.prevent="editaInfoPessoal()">editar</b-button>
                      <b-button v-else variant="outline-dark" @click.prevent="editaInfoPessoal()">cancelar edição</b-button>
                    </span>

                    <!-- #region edicao -->
                    <vs-form-usuario 
											v-if="isEdicao" 
											:obj="cli.item" 
											:arrayPerfis="formCliente.perfis"
                      :isEdicao="true" 
											:exibeEndereco="true" 
											:exibeFoto="true" 
											:exibeExclusao="!cli.item.cadastroPivot" 
											:exibeSenha="$root.sessao.perfil === VS_PERFIL_CONTRATANTE"
                      :emailObrigatorio="true"
											v-on:evento="listaCadastros" />
                    <!-- #endregion -->

                    <!-- #region resumo/leitura -->
                    <div v-else class="mt-3 ml-3 h5">
                      <div>Nome&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ cli.item.nome }}</div>
                      <div>Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ cli.item.email }}</div>
                      <div>CPF/CNPJ&nbsp;&nbsp;&nbsp;{{ $vsFormataCpfCnpj(cli.item.cpfCnpj) }}</div>
                      <div>Telefone&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $vsFormataTelefone(cli.item.telefone) }}</div>
											<div>{{ cli.item.endereco.logradouro }}, {{ cli.item.endereco.numero }}<span v-if="cli.item.endereco.complemento.length">, {{ cli.item.endereco.complemento }}</span></div>
											<div>{{ $vsFormataCep(cli.item.endereco.cep) }} - {{ cli.item.endereco.bairro }}, {{ cli.item.endereco.cidade }}/{{ cli.item.endereco.uf }}</div>
                    </div>
                    <!-- #endregion -->
                  </b-col>
                  <!-- #endregion -->

                  <!-- #region box saldo -->
                  <b-col md="4">
                    <div class="h3" v-if="cli.item.contratos[cli.item.contratoAtual].tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
                      Saldo:
                      <span v-if="cli.item.contratos[cli.item.contratoAtual].saldoFinal > 0" class="float-right text-success">
                        R$ {{ $vsFormataMoeda(cli.item.contratos[cli.item.contratoAtual].saldoFinal) }}
                      </span>
                      <span v-if="cli.item.contratos[cli.item.contratoAtual].saldoFinal < 0" class="float-right text-danger">
                        R$ {{ $vsFormataMoeda(cli.item.contratos[cli.item.contratoAtual].saldoFinal) }}
                      </span>
                      <span v-if="cli.item.contratos[cli.item.contratoAtual].saldoFinal === 0" class="float-right text-secondary">
                        R$ 0
                      </span>
                    </div>
                    <div class="mt-3 h5">
                      <div class="mb-4">Último pagamento: <span class="float-right">{{ $vsFormataData(cli.item.contratos[cli.item.contratoAtual].dataUltimoPagamento) }}</span></div>

                      <!-- #region acoes -->
                      <b-col v-if="cli.item.contratos[cli.item.contratoAtual].permiteBloqueio" cols="7" class="float-right">
                        <button type="button" class="btn-laranja" @click.prevent v-b-modal="`bloqueiaContrato${cli.item.id}`" v-b-tooltip.hover title="Bloquear contrato">Suspender contrato</button>

												<!-- #region modal de suspensao -->
                        <b-modal :id="`bloqueiaContrato${cli.item.id}`" title="Alerta">
                          <div>
                            <div class="mb-4">Deseja mesmo suspender o contrato?</div>
                            <div>CPF:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $vsFormataCpfCnpj(cli.item.cpfCnpj) }}</div>
                            <div>Nome:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ cli.item.nome }}</div>
                            <div>Tipo de contrato:&nbsp;&nbsp;{{ tiposContrato[cli.item.contratoAtual].text }}</div>
                          </div>
                          <template #modal-footer="{ hide }">
                            <div class="w-100">
                              <b-button class="float-right ml-2" variant="danger" @click="mudarStatusContrato(cli.item, VS_CONTRATO_STATUS_SUSPENSO); hide()">Suspender</b-button>
                              <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
														</div>
                          </template>
                        </b-modal>
												<!-- #endregion -->
                      </b-col>
                      <b-col v-if="cli.item.contratos[cli.item.contratoAtual].permiteLiberacao" cols="6" class="float-right">
                        <button type="button" class="btn-verde" @click="mudarStatusContrato(cli.item, VS_CONTRATO_STATUS_ATIVO)" v-b-tooltip.hover title="Liberar contrato">Liberar contrato</button>
                      </b-col>
                      <!-- #endregion -->
                    </div>
                  </b-col>
                  <!-- #endregion -->

                  <!-- #region box contrato -->
                  <b-col cols="12" class="border-top mt-3 pt-3">
                    <span class="h3">Dados contratuais</span>&nbsp;

                    <span v-if="visualizarStatusContrato(cli.item.contratos[cli.item.contratoAtual])">
                      <!-- #region status do contrato -->
                      <span :class="$vsListaContratosStatus(cli.item.contratos[cli.item.contratoAtual].status).class">
                        <b-icon :icon="$vsListaContratosStatus(cli.item.contratos[cli.item.contratoAtual].status).icon" scale="1.3" />
                        {{ $vsListaContratosStatus(cli.item.contratos[cli.item.contratoAtual].status).text }}
                      </span>
                      <!-- #endregion -->

                      <!-- #region acoes -->
                      <a class="float-right mr-5 mt-2" @click.prevent v-b-modal="`apagaContrato${cli.item.id}`" v-b-tooltip.hover title="Excluir contrato">
                        <b-icon icon="trash" variant="danger" scale="1.5" />
                      </a>

											<!-- #region modal de exclusao -->
                      <b-modal :id="`apagaContrato${cli.item.id}`" title="Alerta">
                        <div>
                          <div class="mb-4">Deseja mesmo excluir o contrato?</div>
                          <div>CPF:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $vsFormataCpfCnpj(cli.item.cpfCnpj) }}</div>
                          <div>Nome:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ cli.item.nome }}</div>
                          <div>Tipo de contrato:&nbsp;&nbsp;{{ tiposContrato[cli.item.contratoAtual].text }}</div>
                        </div>
                        <template #modal-footer="{ hide }">
                          <div class="w-100">
                            <b-button class="float-right ml-2" variant="danger" @click="apagarContrato(cli.item); hide()">Excluir</b-button>
                            <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                          </div>
                        </template>
                      </b-modal>
											<!-- #endregion -->
                      <!-- #endregion -->
                    </span>

                    <div class="mt-3 ml-3">
                      <b-form-row>
                        <b-col cols="12">
                          <b-form-group label="Tipo de contrato" label-size="lg" label-cols="2" content-cols-md="2">
                            <b-form-select :value="tiposContrato[0].value" :options="tiposContrato" size="sm" @change="trocaContrato($event, cli.item)" />&nbsp;&nbsp;
														<b-form-checkbox v-if="!visualizarStatusContrato(cli.item.contratos[cli.item.contratoAtual])" class="form-input-cinza" size="lg" v-model="cli.item.contratos[cli.item.contratoAtual].isPadrao" switch inline>
															<span style="cursor: pointer">Definir como padrão</span>
														</b-form-checkbox>
														<span v-else-if="cli.item.contratos[cli.item.contratoAtual].isPadrao">Definido como padrão</span>
                          </b-form-group>
                        </b-col>
                      </b-form-row>

                      <!-- #region resumo/leitura -->
                      <b-form-row v-if="visualizarStatusContrato(cli.item.contratos[cli.item.contratoAtual])">
                        <b-col cols="12">
                          <h5>
                            <!-- #region geral -->
                            <div class="mb-4">
                              <div>
																Vigência: {{ $vsFormataData(cli.item.contratos[cli.item.contratoAtual].dataInicio) }} à {{ $vsFormataData(cli.item.contratos[cli.item.contratoAtual].dataTermino) }}
															</div>
                              <div v-if="cli.item.contratos[cli.item.contratoAtual].tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
                                Limite de saldo negativo: R$ {{ $vsFormataMoeda(cli.item.contratos[cli.item.contratoAtual].saldoLimite) }}
                              </div>
                              <div v-if="cli.item.contratos[cli.item.contratoAtual].tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
                                Dia de fechamento: {{ cli.item.contratos[cli.item.contratoAtual].diasFechamento }}
                              </div>
                              <div v-if="cli.item.contratos[cli.item.contratoAtual].tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
                                Vencimento (dias corridos): {{ cli.item.contratos[cli.item.contratoAtual].diasVencimento }}
                              </div>
                            </div>
                            <!-- #endregion -->

                            <!-- #region taxas -->
                            <div>
                              <div class="laranja mb-2">Ajuste de taxas</div>
                              <div>Desconto R$ {{ $vsFormataMoeda(cli.item.contratos[cli.item.contratoAtual].taxas[0].valorDesconto) }}</div>
                              <div>Desconto % {{ $vsFormataMoeda(cli.item.contratos[cli.item.contratoAtual].taxas[0].percentualDesconto) }}</div>
                              <div>Acréscimo R$ {{ $vsFormataMoeda(cli.item.contratos[cli.item.contratoAtual].taxas[0].valorAcrescimo) }}</div>
                              <div>Acréscimo % {{ $vsFormataMoeda(cli.item.contratos[cli.item.contratoAtual].taxas[0].percentualAcrescimo) }}</div>
                            </div>
                            <!-- #endregion -->
                          </h5>
                        </b-col>
                      </b-form-row>
                      <!-- #endregion -->

                      <!-- #region edicao -->
                      <b-form-row v-else>
												<!-- #region geral -->
                        <b-col md="4">
                          <b-form-group label="Início vigência" label-size="lg" label-cols="6" content-cols-md="6">
                            <b-form-datepicker v-model="cli.item.contratos[cli.item.contratoAtual].dataInicio" locale="pt-BR" size="sm" placeholder="dd/mm/aaaa" :date-format-options="formatoData" :min="new Date().toISOString().substring(0, 10)" />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group label="Fim vigência" label-size="lg" label-cols="5" content-cols-md="6">
                            <b-form-datepicker v-model="cli.item.contratos[cli.item.contratoAtual].dataTermino" locale="pt-BR" size="sm" placeholder="dd/mm/aaaa" :date-format-options="formatoData" :min="new Date().toISOString().substring(0, 10)" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="cli.item.contratos[cli.item.contratoAtual].tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
                          <b-form-group label="Limite de saldo negativo" label-size="lg" label-cols="3" content-cols-md="2">
                            <b-form-input class="text-right" v-model="cli.item.contratos[cli.item.contratoAtual].saldoLimite" type="text" size="sm" @keyup="mascaraMoeda" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="5" v-if="cli.item.contratos[cli.item.contratoAtual].tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
                          <b-form-group label="Dia de fechamento" label-size="lg" label-cols="8" content-cols-md="3">
                            <b-form-select v-model="cli.item.contratos[cli.item.contratoAtual].diasFechamento" :options="diasFechamento" size="sm" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="7" v-if="cli.item.contratos[cli.item.contratoAtual].tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
                          <b-form-group label="Vencimento (dias corridos)" label-size="lg" label-cols="6" content-cols-md="3">
                            <b-form-select v-model="cli.item.contratos[cli.item.contratoAtual].diasVencimento" :options="diasVencimento" size="sm" />
                          </b-form-group>
                        </b-col>
												<!-- #endregion -->

												<!-- #region taxas -->
                        <b-col cols="12">
                          <b-form-row>
                            <b-col cols="12 mb-3">
                              <span class="h3 laranja">Ajuste de taxas</span>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Desconto R$" label-size="lg" label-cols="4" content-cols-md="4">
                                <b-form-input class="text-right" v-model="cli.item.contratos[cli.item.contratoAtual].taxas[0].valorDesconto" type="text" size="sm" @keyup="mascaraMoeda" />
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Desconto %" label-size="lg" label-cols="3" content-cols-md="4">
                                <b-form-input class="text-right" v-model="cli.item.contratos[cli.item.contratoAtual].taxas[0].percentualDesconto" type="text" size="sm" @keyup="mascaraMoeda" />
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Acréscimo R$" label-size="lg" label-cols="4" content-cols-md="4">
                                <b-form-input class="text-right" v-model="cli.item.contratos[cli.item.contratoAtual].taxas[0].valorAcrescimo" type="text" size="sm" @keyup="mascaraMoeda" />
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Acréscimo %" label-size="lg" label-cols="3" content-cols-md="4">
                                <b-form-input class="text-right" v-model="cli.item.contratos[cli.item.contratoAtual].taxas[0].percentualAcrescimo" type="text" size="sm" @keyup="mascaraMoeda" />
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </b-col>
												<!-- #endregion -->
                      </b-form-row>
                      <!-- #endregion -->

                      <!-- #region acoes -->
                      <b-form-row v-if="!visualizarStatusContrato(cli.item.contratos[cli.item.contratoAtual])">
                        <b-col md="3">
                          <button type="button" class="btn-azul" @click="cadastrarContrato(cli.item)">Aplicar</button>
                        </b-col>
                      </b-form-row>
                      <!-- #endregion -->
                    </div>
                  </b-col>
                  <!-- #endregion -->
                </b-form-row>
              </b-collapse>
              <!-- #endregion -->
            </div>
          </template>
        </b-table>

				<!-- #region paginacao -->
        <div v-if="formCliente.cadastros.length" class="mt-4">
          <span style="padding-left: 5px;padding-right: 20px;">
            <b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
          </span>
          <span>Total de Regitros: {{ $vsFormataNumero(formCliente.cadastros.length) }}</span>
          <b-pagination class="mt-3 d-flex justify-content-center" :total-rows="formCliente.cadastros.length" :per-page="totalPagina" v-model="paginaAtual" aria-controls="tabelaCliente" />
        </div>
				<!-- #endregion -->
      </div>
      <!-- #endregion -->
    </div>
  </div>
</template>

<script>
import VsFormUsuario from "../templates/vs-form-usuario.vue"

export default {
  // #region configs
  name: "clientes",
  path: "/clientes",
  requireAuth: true,
  components: { VsFormUsuario },
  // #endregion

  // #region dados
  data() {
    return {
			liberaSenhaCadastro: false,
      idSanfonaAberta: null,
      formCliente: null,
      isEdicao: false,
      tiposContrato: [],
      periodosPagamento: [],
			convitesEnviados: [],
      diasFechamento: Array.from({ length: 30 }, (v, k) => k + 1),
      diasVencimento: Array.from({ length: 60 }, (v, k) => k + 1),
      filtro: {
        nome: "",
        cpfCnpj: "",
        email: "",
        telefone: "",
        cep: "",
      },
      formatoData: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      cabecalhoTabelaCliente: [
        { key: "cliente", sortable: false, label: "Cliente", thClass: "d-none", tdClass: "py-2" },
      ],
      paginaAtual: 1,
      totalPagina: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_CONTRATANTE, this.VS_PERFIL_FILIAL, this.VS_PERFIL_GERENTE])

    // cadastro basico
    this.formCliente = this.formBasico([
      { value: this.VS_PERFIL_CLIENTE, text: "Cliente" },
    ])

    // combos
    this.tiposContrato = this.$vsListaContratos()
    this.periodosPagamento = this.$vsListaContratosPeriodo()

		// cadastro de senha
		this.liberaSenhaCadastro = (this.$root.sessao.perfil === this.VS_PERFIL_CONTRATANTE)
  },
  // #endregion

  // #region metodos
  methods: {
		async pesquisaCpfCnpj() {
			let form = this.formCliente
			let sessao = this.$root.sessao
			let filtro = this.filtro
			let cpfCnpj = this.$vsLimpaFormatacao(form.cpfCnpj)

			if (![11, 14].includes(cpfCnpj.length)) {
				return
			}

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				let query = `cpfCnpj=${cpfCnpj}&cadastroPivot=true`
				let resp = await this.$vsApiRest("GET", `/usuarios/clientes/?${query}`)

				this.liberaSenhaCadastro = true

				if (resp.status === 200 && resp.data.length) {
					this.liberaSenhaCadastro = false
					form.senha = ""
				}
			}

			await this.listaCadastros(cpfCnpj)

			if (form.cadastros.length) {
				this.$vsNotificacao(`CPF/CNPJ: ${this.$vsFormataCpfCnpj(cpfCnpj)} já está cadastrado, verifique a lista abaixo`, "info")
			}
		},
    formBasico(comboPerfis) {
			let obj = {
        nome: "",
        fotoUpload: null,
        telefone: "",
        cpfCnpj: "",
        email: "",
        senha: "",
        isAtivo: true,
        isExcluido: false,
        verSenha: false,
        mensagemCustomizada: "",
        perfil: comboPerfis[0].value,
        perfis: comboPerfis,
        cadastros: [],
        vinculos: [],
        endereco: {
          tipo: this.VS_TIPO_ENDERECO_RUA,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          apelido: "",
          complemento: "",
          isComercial: false,
        }
      }
			this.vinculosDefault(obj)

      return obj
    },
		vinculosDefault(obj) {
			let sessao = this.$root.sessao

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				obj.vinculos.push({ idUsuario: sessao.id,	isSuperior: true,	})
			}
			if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				obj.vinculos.push({	idUsuario: sessao.contratante.id,	isSuperior: true,	})
				obj.vinculos.push({	idUsuario: sessao.id,	isSuperior: true,	})
			}
			if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				obj.vinculos.push({	idUsuario: sessao.contratante.id,	isSuperior: true,	})
				obj.vinculos.push({	idUsuario: sessao.filial.id, isSuperior: true, })
				obj.vinculos.push({	idUsuario: sessao.id,	isSuperior: true,	})
			}
		},
    async mudarStatus(obj) {
      let status = !obj.isAtivo
      let resp = await this.$vsApiRest("PUT", `/usuarios/status/${obj.id}/${status}`)
      
			if (resp.status === 200) {
        obj.isAtivo = status
        this.$vsNotificacao(resp.data.msg, "success")
      }
    },
    async listaCadastros(cpfCnpjInsert="") {
      this.formCliente.cadastros = []

			let sessao = this.$root.sessao
			let filtro = this.filtro
      let cpfCnpj = this.$vsLimpaFormatacao((cpfCnpjInsert.length) ? cpfCnpjInsert : filtro.cpfCnpj)
      let telefone = this.$vsLimpaFormatacao(filtro.telefone)
      let cep = this.$vsLimpaFormatacao(this.filtro.cep)
			let idSuperior = (sessao.perfil === this.VS_PERFIL_GERENTE)
				? sessao.filial.id
				: sessao.id

      let query =  `idFilial=${idSuperior}`

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				query = `idContratante=${idSuperior}`
			}
			if (sessao.perfil !== this.VS_PERFIL_CONTRATANTE) {
				query += `&idContratante=${sessao.contratante.id}`
			}
      if (filtro.nome.length >= 3) {
        query += `&nome=${filtro.nome}`
      }
      if ([11, 14].includes(cpfCnpj.length)) {
        query += `&cpfCnpj=${cpfCnpj}`
      }
      if (filtro.email.length > 0) {
        query += `&email=${filtro.email}`
      }
      if ([10, 11].includes(telefone.length)) {
        query += `&telefone=${telefone}`
      }
      if (cep.length === 8) {
        query += `&cep=${cep}`
      }

			this.listaConvites()

      let resp = await this.$vsApiRest("GET", `/usuarios/clientes/?${query}`)
      
			if (resp.status === 200) {
        for (let i = 0; i < resp.data.length; ++i) {
          this.configuraEdicao(resp.data[i])
        }
        this.formCliente.cadastros = resp.data
      }
    },
    configuraEdicao(obj) {
			let sessao = this.$root.sessao
			let idUsuario = (sessao.perfil === this.VS_PERFIL_GERENTE)
				? sessao.filial.id
				: sessao.id
      let tempContratos = this.novosContratos()

      // dados originais
      obj.fotoUpload = null
      obj.cpfCnpj = this.$vsFormataCpfCnpj(obj.cpfCnpj)
      obj.telefone = this.$vsFormataTelefone(obj.telefone)
      obj.senha = ""

			if (!obj.mensagemCustomizada) {
				obj.mensagemCustomizada = ""
			}

			if (obj.endereco) {
				obj.endereco.complemento = obj.endereco.complemento || ""
			}

      // dados complementares
      obj.edicao = true
      obj.contratoAtual = 0
      obj.totalContratoAtivo = 0
      obj.totalContratoInativo = 0
      obj.totalContratoSuspenso = 0
      
			if (!obj.contratos) {
				obj.contratos = []
			}
			if (!obj.vinculos) {
				obj.vinculos = []
			}
			if (obj.vinculos.length === 0) {
				this.vinculosDefault(obj)
			}

      // filtra contratos validos vinculados ao contratante/filial logada,
			// completa contratos faltantes e reordena
      obj.contratos.forEach(el => {
        for (let i = 0; i < tempContratos.length; i++) {
          if (!el.isExcluido && el.idFilial === idUsuario && el.tipo === tempContratos[i].tipo) {
            this.defineAcoesContrato(el)
            this.estatisticaContrato(el)
            el.saldoFinal = el.saldoLimite - el.saldoUtilizado
            tempContratos[i] = el
          }
        }
      })
      obj.contratos = tempContratos

      this.$vsContratosOrdenacao(obj.contratos)
      this.estatisticaGeralContratos(obj)
    },
    novosContratos() {
			let sessao = this.$root.sessao
      let idContratante = (sessao.perfil === this.VS_PERFIL_CONTRATANTE)
        ? sessao.id
        : sessao.contratante.id
      let idFilial = [this.VS_PERFIL_CONTRATANTE, this.VS_PERFIL_FILIAL].includes(sessao.perfil)
        ? sessao.id
        : sessao.filial.id
			let lista = []

      for (let i = 0; i < this.tiposContrato.length; i++) {
        let isFidelidade = (this.tiposContrato[i].value === this.VS_CONTRATO_TIPO_FIDELIDADE)

        lista.push({
          // dados originais
          status: this.VS_CONTRATO_STATUS_ATIVO,
          idContratante: idContratante,
          idFilial: idFilial,
          idGerente: sessao.id,
          isExcluido: false,
          tipo: this.tiposContrato[i].value,
          dataUltimoPagamento: null,
          dataInicio: null,
          dataTermino: null,
          saldoLimite: 0,
          saldoUtilizado: 0,
          saldoFinal: 0,
          periodoPagamento: this.VS_CONTRATO_PERIODO_MENSAL,
          diasFechamento: isFidelidade ? 0 : this.diasFechamento[0],
          diasVencimento: isFidelidade ? 0 : this.diasVencimento[0],
          taxas: [{
            valorDesconto: 0,
            valorAcrescimo: 0,
            percentualDesconto: 0,
            percentualAcrescimo: 0,
          }],

          // dados complementares
          permiteBloqueio: false,
          permiteLiberacao: false,
          isContratoAtivo: false,
          isContratoInativo: false,
          isContratoSuspenso: false,
        })
      }
      return lista
    },
    async mudarStatusContrato(obj, status) {
      let contrato = obj.contratos[obj.contratoAtual]
      let url = `/contratos/status/${obj.id}/${contrato.idContratante}/${contrato.idFilial}/${contrato.tipo}/${status}`
			let resp = await this.$vsApiRest("PUT", url)
      
			if (resp.status === 200) {
        contrato.status = status
        this.defineAcoesContrato(contrato)
        this.estatisticaGeralContratos(obj)
        this.estatisticaContrato(contrato)
        this.$vsNotificacao(resp.data.msg, "success")
      }
    },
    async cadastrarContrato(obj) {
      // ajustes basicos
      let contrato = obj.contratos[obj.contratoAtual]
      let dataUltimoPagamento = contrato.dataUltimoPagamento

      contrato.saldoLimite = this.$vsConverteMoeda(contrato.saldoLimite)
      contrato.taxas[0].valorDesconto = this.$vsConverteMoeda(contrato.taxas[0].valorDesconto)
      contrato.taxas[0].percentualDesconto = this.$vsConverteMoeda(contrato.taxas[0].percentualDesconto)
      contrato.taxas[0].valorAcrescimo = this.$vsConverteMoeda(contrato.taxas[0].valorAcrescimo)
      contrato.taxas[0].percentualAcrescimo = this.$vsConverteMoeda(contrato.taxas[0].percentualAcrescimo)
      delete contrato.dataUltimoPagamento   // evita conflitos no backend (temporario)

      if (!this.validarContrato(contrato)) {
        return
      }

      // grava
      let resp = await this.$vsApiRest("POST", `/contratos/${obj.id}`, contrato)
      
			if (resp.status === 200) {
        contrato.dataUltimoPagamento = dataUltimoPagamento // evita conflitos no frontend (temporario)

        this.$vsNotificacao(resp.data.msg, "success")
        this.defineAcoesContrato(contrato)
        this.estatisticaGeralContratos(obj)
        this.estatisticaContrato(contrato)
      }
    },
    trocaContrato(event, obj) {
      // simula carregamento
      this.$vsLoadingStart(true, 200)

      for (let i = 0; i < this.tiposContrato.length; i++) {
        if (event === this.tiposContrato[i].value) {
          obj.contratoAtual = i
          break
        }
      }
    },
    defineAcoesContrato(cont) {
      let status = (cont.status !== this.VS_CONTRATO_STATUS_SUSPENSO)
      cont.permiteBloqueio = status
      cont.permiteLiberacao = !status
    },
    estatisticaGeralContratos(obj) {
      obj.totalContratoAtivo = 0
      obj.totalContratoInativo = 0
      obj.totalContratoSuspenso = 0

      obj.contratos.forEach((el) => {
        if (el.permiteLiberacao || el.permiteBloqueio) {
          switch (el.status) {
            case this.VS_CONTRATO_STATUS_ATIVO:
              obj.totalContratoAtivo++
              break
            case this.VS_CONTRATO_STATUS_INATIVO:
              obj.totalContratoInativo++
              break
            case this.VS_CONTRATO_STATUS_SUSPENSO:
              obj.totalContratoSuspenso++
              break
          }
        }
      })
    },
    estatisticaContrato(cont) {
      cont.isContratoAtivo = false
      cont.isContratoInativo = false
      cont.isContratoSuspenso = false

      if (cont.permiteLiberacao || cont.permiteBloqueio) {
        switch (cont.status) {
          case this.VS_CONTRATO_STATUS_ATIVO:
            cont.isContratoAtivo = true
            break
          case this.VS_CONTRATO_STATUS_INATIVO:
            cont.isContratoInativo = true
            break
          case this.VS_CONTRATO_STATUS_SUSPENSO:
            cont.isContratoSuspenso = true
            break
        }
      }
    },
    visualizarStatusContrato(cont) {
      return (cont.permiteLiberacao || cont.permiteBloqueio)
    },
    async apagarContrato(obj) {
      let contrato = obj.contratos[obj.contratoAtual]
      let url = `/contratos/${obj.id}/${contrato.idContratante}/${contrato.idFilial}/${contrato.tipo}/${this.$root.sessao.id}`
			let resp = await this.$vsApiRest("DELETE", url)
      
			if (resp.status === 200) {
        contrato.dataInicio = ""
        contrato.dataTermino = ""
        contrato.saldoLimite = ""
        contrato.saldoUtilizado = 0
        contrato.diasFechamento = 1
        contrato.diasVencimento = 1
        contrato.taxas[0].valorDesconto = ""
        contrato.taxas[0].valorAcrescimo = ""
        contrato.taxas[0].percentualDesconto = ""
        contrato.taxas[0].percentualAcrescimo = ""
        contrato.permiteLiberacao = false
        contrato.permiteBloqueio = false

        this.estatisticaGeralContratos(obj)
        this.estatisticaContrato(contrato)
        this.$vsNotificacao(resp.data.msg, "success")
      }
    },
    editaInfoPessoal() {
      // simula carregamento
      this.$vsLoadingStart(true, 100)
      this.isEdicao = !this.isEdicao
    },
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    mascaraCpfCnpj() {
      this.filtro.cpfCnpj = this.$vsFormataCpfCnpj(this.filtro.cpfCnpj)
    },
    mascaraTelefone() {
      this.filtro.telefone = this.$vsFormataTelefone(this.filtro.telefone)
    },
    mascaraCep() {
      this.filtro.cep = this.$vsFormataCep(this.filtro.cep)
    },
    validarContrato(cont) {
      let ok = true

      if (!cont.dataInicio) {
        this.$vsNotificacao("Informe o Início da vigência para o contrato", "warning")
        ok = false
      }
      if (!cont.dataTermino) {
        this.$vsNotificacao("Informe o Fim da vigência para o contrato", "warning")
        ok = false
      } else if (cont.dataTermino < cont.dataInicio) {
        this.$vsNotificacao("Fim da vigência do contrato não pode ser menor que o Início", "warning")
        ok = false
      }
      return ok
    },
    lazyLoadSanfona(id) {
      this.$vsLoadingStart(true, 300)
      this.idSanfonaAberta = id
    },
		async listaConvites() {
			let sessao = this.$root.sessao
			let idConvidante = (sessao.perfil === this.VS_PERFIL_GERENTE) ? sessao.filial.id : sessao.id
			let query = `idConvidante=${idConvidante}`
			let resp = await this.$vsApiRest("GET", `/usuarios/convites/?${query}`)
      
			if (resp.status === 200) {
        this.convitesEnviados = resp.data
      }
		},
		async enviaConvite(obj) {
			let sessao = this.$root.sessao
			let req = {
				idConvidante: (sessao.perfil === this.VS_PERFIL_GERENTE) ? sessao.filial.id : sessao.id,
				idConvidado: obj.id,
			}
			let resp = await this.$vsApiRest("POST", "/usuarios/convites", req)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Convite enviado com sucesso", "info")
				this.listaConvites()
      }
      
		},
		liberaEnvioConvite(obj) {
			let busca = this.convitesEnviados.find(el => el.emailConvidado === obj.email)
			let ok = true

			if (busca) {
				ok = (busca.situacao === this.VS_STATUS_USUARIO_CONVITE_AGUARDANDO && new Date(busca.dataExpiracaoToken) < new Date((new Date()).toISOString()))
			}
			return ok
		},
		conviteAguardando(obj) {
			let busca = this.convitesEnviados.find(el => el.emailConvidado === obj.email)
			return (busca && busca.situacao === this.VS_STATUS_USUARIO_CONVITE_AGUARDANDO)
		},
		conviteAceito(obj) {
			let busca = this.convitesEnviados.find(el => el.emailConvidado === obj.email)
			return (busca && busca.situacao === this.VS_STATUS_USUARIO_CONVITE_ACEITO)
		},
		conviteRecusado(obj) {
			let busca = this.convitesEnviados.find(el => el.emailConvidado === obj.email)
			return (busca && busca.situacao === this.VS_STATUS_USUARIO_CONVITE_RECUSADO)
		},
		conviteDataExpiracao(obj) {
			let busca = this.convitesEnviados.find(el => el.emailConvidado === obj.email)
			return (busca) ? busca.dataExpiracaoToken : new Date("1000-01-01T00:00:00.000Z")
		},
		liberaEdicaoCliente(obj) {
			let sessao = this.$root.sessao

			if (obj.cadastroPivot) {
				return false
			}
			return (sessao.perfil === this.VS_PERFIL_CONTRATANTE || obj.responsaveis.length == 0 || !obj.responsaveis.includes(sessao.contratante.id))
		},
  }
  // #endregion
}
</script>
